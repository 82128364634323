.ant-table-thead > tr > th {
  white-space: break-spaces !important;
  width: min-content;
}

.ant-table-tbody > tr > td {
  white-space: nowrap !important;
  width: min-content;
}

.ant-table-content {
  overflow: scroll !important;
  max-height: calc(100vh - 280px);
}
.ant-table-thead > tr > th {
  position: sticky !important;
  top: 0 !important;
  z-index: 100 !important;
}
.ant-form-item label,
.ant-select-selection-placeholder {
  text-transform: capitalize;
}

.new-line-textarea {
  white-space: pre-wrap;
}
._ant-select-multiple .ant-select-selector {
  min-height: 12px !important;
  padding: 0px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  min-height: 12px !important;
  height: 18px !important;
  line-height: 14px !important;
  // margin-top: 0px;
}

.fc .fc-popover {
  z-index: 200 !important;
}
.upperFilterBackground {
  background-color: #f6f6f6;
  padding: 5px !important;
  border-radius: 10px;
}
.inlineForm {
  .ant-form-item-label > label {
    height: 25px;
  }
  .ant-form-item {
    display: grid;
    align-items: flex-end;
    margin-bottom:5px!important
  }
  .ant-form-item-label {
    text-align: left;
    padding: 0px;
  }
}


.ant-drawer-content {
  overflow: hidden!important;

}

// .ant-descriptions-row .ant-descriptions-item-label{
//   white-space: nowrap!important;
//   width: fit-content!important;
  
// }

// .ant-descriptions-row .ant-descriptions-item-content  {
//   white-space: pre-wrap;
//   width: auto!important;
// }